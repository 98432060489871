import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCuPRUmJlMciHOdhYWSvvTszlQ6SZY1luY",
  authDomain: "meethon.firebaseapp.com",
  projectId: "meethon",
  storageBucket: "meethon.appspot.com",
  messagingSenderId: "751154931931",
  appId: "1:751154931931:web:54c05f6024c4c2e03604fe",
  measurementId: "G-G6Z0F9EDQ7",
};

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const auth = getAuth(app);

export { auth, firestore };
